<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '950px' }"
    header="Formulario Detalle"
    :modal="true"
    class="p-fluid"
    :maximizable="true"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-5">
          <span
            ><strong>T&Iacute;TULO: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            v-model="data_frmpredefinido.titulo"
            required="true"
            placeholder="Nombre Completo"
            autofocus
          />
          <small class="p-invalid" v-if="errors.titulo">{{
            errors.titulo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-5">
          <span
            ><strong>DESCRIPCI&Oacute;N CORTA: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            v-model="data_frmpredefinido.descripcion"
            required="true"
            placeholder="Descripción"
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_frmpredefinido.estado"
            :disabled="!data_frmpredefinido.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>CONTENIDO DEL FORMULARIO: </strong></span>
          <quill-editor
            ref="quillEditor"
            v-model:content="data_frmpredefinido.contenido"
            :options="opcionesQuill"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_frmpredefinido.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_frmpredefinido.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="saveFormulario"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import FrmPredefinidoService from "@/service/FrmPredefinidoService";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    QuillEditor,
  },
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    frmpredefinido: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  frmpredefinidoService: null,
  rolService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_frmpredefinido: this.frmpredefinido,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      opcionesQuill: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ header: 1 }, { header: 2 }],
            [{ header: [1, 2, false] }],
            [{ font: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  created() {
    this.frmpredefinidoService = new FrmPredefinidoService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    saveFormulario() {
      this.errors = {};
      this.enviado = true;
      if (this.frmpredefinido.id) {
        let paqueteproducto_enviar = {
          ...this.data_frmpredefinido,
          estado: this.data_frmpredefinido.estado.value,
          contenido: this.$refs.quillEditor.getQuill().getContents(),
        };
        this.frmpredefinidoService
          .updateFrm(paqueteproducto_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        let tmp = this.data_frmpredefinido;
        this.data_frmpredefinido = {
          ...this.data_frmpredefinido,
          estado: this.data_frmpredefinido.estado.value,
          contenido: this.$refs.quillEditor.getQuill().getContents(),
        };
        this.frmpredefinidoService
          .sendFrmNew(this.data_frmpredefinido)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_frmpredefinido = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 10000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    frmpredefinido(val) {
      this.data_frmpredefinido = val;
      if (this.data_frmpredefinido.id) {
        this.$nextTick(() => {
          const quillEditor = this.$refs.quillEditor;

          if (quillEditor && quillEditor.getQuill) {
            let contenido = this.data_frmpredefinido.contenido;

            // Verifica si el contenido es un string y conviértelo a JSON si es necesario
            if (typeof contenido === "string") {
              try {
                contenido = JSON.parse(contenido);
                //me aseguro que de bien el enter en el contenido
                contenido = contenido.map((item) => {
                  if (item.insert === null) {
                    item.insert = ""; // Reemplaza null por una cadena vacía
                  }
                  return item;
                });
              } catch (error) {
                console.error("Error al parsear el contenido:", error);
                return;
              }
            }

            if (Array.isArray(contenido)) {
              contenido = contenido.map((item) => {
                if (item.insert === "") {
                  item.insert = "\n"; // Reemplaza las cadenas vacías por saltos de línea
                }
                return item;
              });

              // Cargar el contenido en el editor Quill
              quillEditor.getQuill().setContents(contenido);
            } else {
              console.error("Contenido no es un array válido:", contenido);
            }
          } else {
            console.error("Quill editor no está disponible");
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
