<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Odontologia</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Formularios Predefinidos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Formularios Predefinidos
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO"
          v-if="'FrmPredefinido Crear' in auth.user.permissions"
          v-tooltip.top="'Nuevo Formulario'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="dtfrmpredefinidos"
        :value="frmpredefinidos"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Formularios Predefinidos"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-dblclick="editFrm($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Medicos..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="titulo" header="TÍTULO"></Column>
        <Column field="descripcion" header="DESCRIPCIÓN"></Column>
        <Column field="realizado_por" header="REALIZADO POR"></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <FrmPredefinidoCreate
      :show="frmDialog"
      :frmpredefinido="frmpredefinido"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarFrm"
    >
    </FrmPredefinidoCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import FrmPredefinidoService from "@/service/FrmPredefinidoService";
import { useAuth } from "@/stores";
import FrmPredefinidoCreate from "@/module/frmpredefinidos/FrmPredefinidoCreate.vue";

export default {
  components: {
    FrmPredefinidoCreate,
  },
  data() {
    return {
      frmpredefinidos: [],
      cargando: true,
      frmDialog: false,
      frmpredefinido: {},
      buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  frmpredefinidoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.frmpredefinidoService = new FrmPredefinidoService();
  },
  mounted() {
    this.cargarFrm();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled:
            "FrmPredefinido Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editFrm(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "FrmPredefinido Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.deleteFrm(datos.id);
          },
        },
      ];
    },
    deleteFrm(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea eliminar el Formulario?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo eliminar",
        rejectLabel: "No, cancelar",
        accept: () => {
          this.frmpredefinidoService.deleteFrm(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.message,
                life: 3000,
              });
              this.cargarFrm();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.message,
                life: 3000,
              });
            }
          });
        },
      });
    },
    cargarFrm() {
      this.frmpredefinidoService.getFrmPredefinidoAll().then((data) => {
        this.frmpredefinidos = data.frmpredefinidos;
        this.cargando = false;
      });
    },
    openNuevo() {
      this.frmpredefinido = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.frmDialog = true;
    },
    ocultarDialog() {
      this.frmDialog = false;
      this.enviado = false;
    },
    editFrm(datos) {
      this.frmpredefinido = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
        contenido: datos.contenido,
      };
      this.frmDialog = true;
      this.enviado = false;
    },
  },
  watch: {
    frmpredefinidos() {
      this.totalRegistros = this.frmpredefinidos.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
